import React from "react";
import "./App.css";
import { HashRouter, Switch, Route } from "react-router-dom";
import Footer from "./Footer";
import Home from "./1";
import Page2 from "./2";
import Page3 from "./3";
import Page4 from "./4";
import Page6 from "./6";
import Page5 from "./5";
import NavBar from "./Components/NavBar";
import Page7 from "./7";

function App() {
  return (
    <>
      <HashRouter>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/1" component={Home} />
          <Route path="/driver" component={Page2} />
          <Route path="/transport" component={Page3} />
          <Route path="/local" component={Page4} />
          <Route path="/outstation" component={Page5} />
          <Route path="/contact" component={Page6} />
          <Route path="/aboutus" component={Page7} />
        </Switch>
        <Footer />
      </HashRouter>
    </>
  );
}

export default App;
