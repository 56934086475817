import React from "react";

export default function Page6() {
  return (
    <>
      <div class="container">
        {" "}
        <section class="container">
          <div className="mt-32"></div>
          <div class="helpcenter text-center">
            <h4 class="text-center font-weight-bold">HELP CENTER</h4>
            <p>
              Need assistance? We’re happy to help, reach us out through the
              appropriate channels below.
            </p>
          </div>
          <div class="text-center">
            <img
              alt=""
              class="icon"
              src="website icons/37.png"
              style={{ width: "150px" }}
            />
          </div>
          <div class="row">
            <div class="text-center col-md-4 col-6 my-3 ">
              <span class="text">To book with us</span>
              <br />
              sales@booktranspo.in
            </div>

            <div class="text-center col-md-4 col-6 my-3">
              <span class="text">To attach trucks</span>
              <br />
              supply@booktranspo.in
            </div>

            <div class="text-center col-md-4 col-6 my-3">
              <span class="text">For other queries</span>
              <br />
              support@booktranspo.in
            </div>
          </div>
          <div class="">
            <div className="row" style={{ maxWidth: "200px", margin: "auto" }}>
              <div className="col-6">
                <img
                  alt=""
                  class="helpcenter-location"
                  src="website icons/38.png"
                  style={{ maxWidth: "100%" }}
                />
              </div>
              <div
                className="col-6"
                style={{ color: "#a6a6a6", padding: "60px 0 0 15px" }}
              >
                <span style={{ fontWeight: "600" }}>
                  HEAD
                  <br /> OFFICE
                  <br /> HYDERABAD
                </span>
              </div>
            </div>
            <p className="text-center my-5">
              16-2-738-F-A, Andhra Colony, New Malakpet, Hyderabad, Telangana
              500036
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
