import React from "react";

export default function Page4() {
  return (
    <>
      <section class="local  container-fluid">
        <div className="mt-20"></div>
        <div class="container">
          <div class="heading text-center">
            <h4 class="text-center font-weight-bold">LOCAL TRIPS</h4>
            <p>Want to move your goods in local here it is for you.</p>
          </div>
          <div class="ls-row row">
            <div class="col-lg-6 col-md-6 mb-5">
              <img alt="" class="h-image" src="website icons/02.png" />
            </div>
            <div class="col-12 col-lg-5 col-md-5 pl-5">
              <div className="flex items-center my-2">
                <span className="bg-white p-4 mr-2 w-10 h-10 font-bold flex justify-center items-center rounded-full text-black">
                  1
                </span>
                <p>
                  Plan your route - supports multiple locations.Enter Pick up
                  and Drop up Location.
                </p>
              </div>
              <div className="flex ">
                <span className="bg-white p-4 mr-2 w-10 h-10 font-bold flex justify-center items-center rounded-full text-black">
                  2
                </span>
                <p>
                  Choose your service options and enter any promocodes that you
                  have for discounts.
                </p>
              </div>
              <div className="flex ">
                <span className="bg-white p-4 mr-2 w-10 h-10 font-bold flex justify-center items-center rounded-full text-black">
                  3
                </span>
                <p>
                  Confirm your details - your Goods are on its way! Sit back and
                  relax- our Transport partners and technology will do the rest.
                </p>
              </div>
              <h5>Download the Booktranspo app on</h5>
              <a href="/">
                <img alt="" class="f-image" src="website icons/20.png" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
