import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <section className="container-fluid contact">
        <div className="row">
          <div className="footer-text col-lg-2 col-md-2 ">
            <h6>COMPANY</h6>
            <Link to="/aboutus" className="d-block">
              About Us
            </Link>
            <a href="/" className="d-block">
              Careers
            </a>
            <a href="/" className="d-block">
              Blog
            </a>
            <a href="/" className="d-block">
              Privacy Policy
            </a>
          </div>
          <div className="footer-text col-lg-2 col-md-2 ">
            <h6>SUPPORT</h6>
            <Link to="/contact" className="d-block">
              Contact Us
            </Link>
            <a href="/" className="d-block">
              Privacy Policy
            </a>
            <a href="/" className="d-block">
              Terms of service
            </a>
            <a href="/" className="d-block">
              Terms & Conditions
            </a>
          </div>
          <div className="footer-text col-lg-2 col-md-2 ">
            <h6>SERVICES</h6>
            <a href="/#/local" className="d-block">
              Local Trips
            </a>
            <a href="/#/outstation" className="d-block">
              OutStation Trips
            </a>
          </div>

          <div className="text-center footer-text col-lg-6 col-md-6 ">
            <h5>Download the Booktranspo app on</h5>
            <a className="mx-2 my-4" href="/">
              <img alt="" className="f-image" src="website icons/20.png" />
            </a>
            <a className="mx-2 my-4" href="/">
              <img alt="" className="f-image" src="website icons/21.png" />
            </a>
            <h5 className="my-3">Follow Us On</h5>
            <a href="/" className="mr-2">
              <img alt="" className="f-icons" src="website icons/19.png" />
            </a>
            <a
              href="https://twitter.com/booktranspo"
              target="_blank"
              rel="noopener noreferrer"
              className="mr-2"
            >
              <img alt="" className="f-icons" src="website icons/18.png" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCH98o_RNZ0VF5InArDz5Rfg/?guided_help_flow=5"
              className="mr-2"
            >
              <img alt="" className="f-icons" src="website icons/17.png" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href=" https://www.linkedin.com/company/31083976/admin/"
              className="mr-2"
            >
              <img alt="" className="f-icons" src="website icons/16.png" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.instagram.com/booktranspo/"
              className="mr-2"
            >
              <img alt="" className="f-icons" src="website icons/15.png" />
            </a>
          </div>
        </div>
      </section>
      <section className="container-fluid">
        <div className="text-center footer ">
          <img alt="" className="footer-image" src="website icons/39.png" />
          <p className="f-text">© Book transport. 2019. All rights reserved.</p>
        </div>
      </section>
    </>
  );
}
