import React from "react";

export default function Page7() {
  return (
    <>
      <section class="local pb-0 container-fluid">
        <div className="mt-20"></div>
        <div class="container">
          <div class="heading text-center">
            <div
              style={{ marginBottom: -200 }}
              className="border-dashed py-3 aboutHeaderBox mx-auto "
            >
              <span className="flex justify-center aboutText -m-2 pt-4 bg-white px-6">
                <p class=" font-bold text-lg">
                  Our Vision Is To Revolutionize The Way India Migrate Goods. We
                  Aim To Make Logistics Very Convienient And Easy in FIRST, MID,
                  LAST MILE
                </p>
              </span>
            </div>
          </div>
          <div class="ls-row row p-0">
            <div class="col-lg-12 col-md-12">
              <img
                alt=""
                class="h-image"
                src="website icons/About Page -01.png"
              />
            </div>
          </div>
        </div>
      </section>
      <div className="mx-10">
        <div className="row p-0 bg-white my-8">
          <div className="col-lg-4 col-md-6 my-4">
            <div className="flex justify-between px-10 text-gray-800 font-black">
              <div className="text-4xl ">35000+</div>
              <div className="text-lg text-right">
                Driver
                <br />
                Added
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  my-4">
            <div className="flex justify-between px-10 text-gray-800 font-black">
              <div className="text-4xl ">200+</div>
              <div className="text-lg text-right">
                Transporters
                <br />
                Added
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  my-4">
            <div className="flex justify-between px-10 text-gray-800 font-black">
              <div className="text-4xl">1000+</div>
              <div className="text-lg text-right">
                Happy
                <br />
                Customers
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container mx-auto mb-4">
        <div className="font-bold text-3xl uppercase my-2 aboutText">
          About Us
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-6 text-xl">
            <div className="text-lg font-semibold">
              It all started back in 2019 with a Problem of small scale
              businesses who depend on Transports to migrate goods around the
              Country. BookTranspo started off as a platform to address
              inefficiencies in Mid mile logistics sector, And We Decided We
              wanted to make Life easier by Transforming the way India Moves.
              Our Transport partners operates all over India --that help you
              deliver goods and parcels of all shapes and sizes. BookTranspo has
              become the strategic partner for businesses of all sizes From
              independent brick and mortar stores to large restaurants, retail
              chains and e-commerce businesses, BookTranspo helps small scale
              businesses from a wide array of industries to scale and outsource
              their deliveries according to their needs. Businesses no longer
              need to invest their time and energies in finding out Transports
              and Auto drivers. Our Tech advantages allow for real-time
              tracking,advance bookings,Online Payments and helps yur business
              to save time and money. Digital receipts emailed to you so you can
              keep Track of all your deliveries.
            </div>
          </div>
          <div className="col-lg-6 col-md-6 my-auto ">
            <div
              style={{ backgroundColor: "#474975" }}
              className="flex justify-center mx-auto my-4 w-64 h-64"
            ></div>
          </div>
        </div>
        <div className="row mt-20">
          <div className="col-lg-6 col-md-6 my-auto ">
            <div
              style={{ backgroundColor: "#474975" }}
              className="flex justify-center mx-auto my-4 w-64 h-64"
            ></div>
          </div>
          <div className="col-lg-6 col-md-6 text-xl">
            <div className="text-lg font-semibold text-right">
              We aspire to Build an Ecosystem which will be known for its Ease
              of use,Precision and The ability to Create Value for everyone
              through its Technology and Service,while also Building World,s
              Most Inspirational Place to work at.At the same time we are
              commited towards improving quality of life of our Partner drivers
              paying them high incentives,Fleixible Working hours and discounted
              insurance and fuel costs. <br />
              <br /> Also We aim to Solve Problems of Our Vendor Transports
              providing them high orders and making them get used to adverse
              benefits of Technology
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-24">
          <div>
            <div className="aboutText uppercase text-4xl font-bold">
              Join BookTranspo
            </div>
            <div className="my-4">
              <span className="px-10 mx-2 py-1  w-24 bg-purple-900 text-white cursor-pointer uppercase rounded text-2xl shadow-md text-center">
                Driver
              </span>
              <span className="px-10 mx-2 py-1 w-24 bg-purple-900 text-white uppercase cursor-pointer rounded text-2xl shadow-md text-center">
                Transport
              </span>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
