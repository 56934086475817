import React from "react";

export default function Page2() {
  return (
    <>
      <div>
        <div className="plain" />
        <div className="container-fluid">
          <section>
            <div className="container text-center">
              <div className="heading2 text-center">
                <h4>OWN A TRUCK? ATTACH WITH BOOKRANSPO</h4>
                <p>Join BookTranspo and Change the way India Migrates</p>
              </div>
              <img alt="" className="truck " src="website icons/22.png" />
            </div>
          </section>
          <section className="container">
            <div className="heading2 text-center">
              <h4>I WOULD LIKE TO ATTACH</h4>
            </div>
            <div className="form">
              <div className="form-row row p-3">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input type="text" className="form-control" id="name" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile</label>
                    <input type="text" className="form-control" id="mobile" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="name">Email</label>
                    <input type="email" className="form-control" id />
                  </div>
                </div>
              </div>
              <div className="form-row row p-3">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="city"> City</label>
                    <input type="text" className="form-control" id="city" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="no_vec">Number of Vehicles</label>
                    <input type="text" className="form-control" id="no_vec" />
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="form-group">
                    <label htmlFor="abt_us">Where did you here about us</label>
                    <input type="text" className="form-control" id="abt_us" />
                  </div>
                </div>
              </div>
            </div>
            <div className="heading2 text-center">
              <h5>VISIT OFFICE TO REGISTER AS A DRIVER PARTNER</h5>
              <p></p>
              <h6>
                1B-63, 64 &amp; 65 on First Floor of Phoenix Paragon Plaza,
                <br />
                Phoenix Market City, L.B.S. Marg, Kurla (W), Mumbai, 400070
              </h6>
              <p />
            </div>
          </section>
          <section className="container">
            <div className="heading2 text-center">
              <h4>BOOKTRANSPO BENIFTS</h4>
            </div>
            <div className="row text-center">
              <div className="col-lg-12 col-md-12">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/28.png"
                />
                <br />
                <span className="benefits-heading"> Better Earning</span>
                <br />
              </div>
            </div>
            <div className="row text-center adj">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/29.png"
                />
                <br />
                <span className="benefits-heading">Regular Trips</span>
                <br />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/40.png"
                />
                <br />
                <span className="benefits-heading">Regular Trips</span>
                <br />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-12 col-md-12">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/26.png"
                />
                <br />
                <span className="benefits-heading">
                  Easy Registration Process
                </span>
                <br />
              </div>
            </div>
            <div className="row text-center adj">
              <div className="col-lg-6 col-md-6 col-sm-6">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/27.png"
                />
                <br />
                <span className="benefits-heading">Fuel Card For Saving</span>
                <br />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/25.png"
                />
                <br />
                <span className="benefits-heading">Attractive EMI schemes</span>
                <br />
              </div>
            </div>
            <div className="row text-center">
              <div className="col-lg-12 col-md-12">
                <img
                  alt=""
                  className="benefits-icons"
                  src="website icons/24.png"
                />
                <br />
                <span className="benefits-heading">Health Care Assistance</span>
                <br />
              </div>
            </div>
          </section>
          <section className="text-center">
            <div className="heading2 text-center">
              <h4>WHAT DRIVERS SAY ABOUT US</h4>
            </div>
            <div className="container">
              <div className="card-columns">
                <div className="card bg-color">
                  <div className="card-body text-center">
                    <img alt="" className="quotes" src="website icons/30.png" />
                    <br />
                    <p className="card-text">
                      I enjoy the simplicity, freedom and flexibility of this
                      platform. I get to plan my jobs around my schedule and
                      take charge of my earnings. The more I deliver, the more I
                      earn.
                    </p>
                    <div className="flex mb-1 pb-1">
                      <img
                        className="w-20 h-20 rounded-full"
                        src="website icons\driver\driver 2.jpg"
                        alt=""
                      />
                    </div>
                    Krishna Babu
                  </div>
                </div>
                <div className="card bg-color">
                  <div className="card-body text-center">
                    <img alt="" className="quotes" src="website icons/30.png" />
                    <br />
                    <p className="card-text">
                      Earlier there were many restrictions on earnings because I
                      got limited trips in the market which were only from my
                      known customers and to nearby places.
                    </p>
                    <div className="flex mb-1 pb-1">
                      <img
                        className="w-20 h-20 rounded-full"
                        src="website icons/driver/2.jpg"
                        alt=""
                      />
                    </div>
                    Bharata Yadav
                  </div>
                </div>
                <div className="card bg-color">
                  <div className="card-body text-center">
                    <img alt="" className="quotes" src="website icons/30.png" />
                    <br />
                    <p className="card-text">
                      As a driver, I remember being on road for 4 days straight
                      to earn a living.Now thaks to Booktranspo, I get to go
                      gome everyday with good earnings
                    </p>
                    <div className="flex mb-1 pb-1">
                      <img
                        className="w-20 h-20 rounded-full"
                        src="website icons\driver\d.jpg"
                        alt=""
                      />
                    </div>
                    Anil Kumar Gupta
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}
