import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Home() {
  const [Screen, setScreen] = useState(1);
  return (
    <>
      {/* <nav class="navbar navbar-expand-lg navbar-light fixed-top">
        <a class="navbar-brand" href="#">
          <img alt="" className="nav-icon" src="website icons/39.png" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="navbar-nav mr-auto"></div>
          <form class="form-inline my-2 my-lg-0 maya">
            <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <Link class="nav-link" to="/2">
                  Driver Partner <span className="sr-only">(current)</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link class="nav-link" to="/3">
                  Transporters
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/">
                  About Us
                </a>
              </li>
              <li className="nav-item">
                <Link class="nav-link" to="/6">
                  Contact Us
                </Link>
              </li>
            </ul>
          </form>
        </div>
      </nav> */}
      <section>
        <div className="container-fluid p-0">
          <div
            className="container-fluid p-0 head_banner"
            style={{ position: "relative" }}
          >
            <svg viewBox="0 0 100 25" className="svgs">
              <path fill="#fff" opacity="0.5" d="M0 30 V15 Q30 3 60 15 V30z" />
              <path fill="#fff" d="M0 30 V12 Q30 17 55 12 T100 11 V30z" />
            </svg>

            <div className="container py-6">
              <div className="row">
                <div className="header-text col-lg-6 col-md-6">
                  <div
                    style={{ color: "#fff" }}
                    className="heading text-center"
                  >
                    <div className="header_text">
                      <h4 className="font-weight-bold">LOCAL TRIPS</h4>
                      <p>
                        Want to move your goods in local here it is for you.
                      </p>
                    </div>

                    <img
                      alt=""
                      className="header-image"
                      src="website icons/Local-01.png"
                    />
                    <Link to="/local" type="Link" className="btn btn-light">
                      Know More
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 mb-5">
                  <div
                    style={{ color: "#fff" }}
                    className="heading text-center"
                  >
                    <div className="header_text">
                      <h4 className="font-weight-bold">OUT STATION TRIPS</h4>
                      <p>
                        Want to move your goods out off district or out off
                        state.
                      </p>
                    </div>
                    <img
                      alt=""
                      className="header-image"
                      src="website icons/Transport_Icon-01.png"
                    />
                    <Link
                      to="/outstation"
                      type="Link"
                      className="btn btn-light"
                    >
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6 ">
            <hr />
            <h5>1. Transports in your pocket</h5>
            <p>
              Book your Favourite Transports online & Send your Goods to any
              part of Country Track on go,with the all-new BT app
            </p>
            <hr />
            <h5>2. Spend less,save more</h5>
            <p className="pl-3">
              Enjoy the most affordable rates with 100% Goods Safety
            </p>
            <hr />
            <h5>3. Real time Tracking</h5>
            <p className="pl-3">
              Know where your Goods is at all times, from the Transport to your
              doorstep
            </p>
            <hr />
            <h5>Download the Booktranspo app on</h5>
            <p>
              <a href="/" className="mr-3">
                <img
                  alt=""
                  className="download-icon"
                  src="website icons/03.png"
                />
              </a>
              <a href="/" className="mr-3">
                <img
                  alt=""
                  className="download-icon"
                  src="website icons/04.png"
                />
              </a>
            </p>
            <hr />
            <p>Get the app download link</p>

            <div className="mobile-no">
              <input type="text" placeholder="Phone " name="number" />
              <button type="button" className="extra-btn btn">
                Text App Link
              </button>
              <br />
            </div>

            <hr />
          </div>
          <div className="col-lg-6 col-md-6 ">
            <div className="plain-header"></div>
          </div>
        </div>
      </section>
      <section className="container align-items-center">
        <div className="heading1 text-center mx-auto">
          <h2 className="text-center font-weight-bold">BE A PARTNER</h2>
        </div>
        <div className="row partner-button mx-auto">
          <div
            className={`text - center col-6 cursor-pointer p-button ${
              Screen === 1 ? "active" : ""
            }`}
            onClick={() => setScreen(1)}
          >
            <p className="font-weight-bold m-0 text-center" href="/">
              DRIVER
            </p>
          </div>
          <div
            className={`text - center col-6 p-button cursor-pointer ${
              Screen === 2 ? "active" : ""
            }`}
            onClick={() => setScreen(2)}
          >
            <p
              className="font-weight-bold m-0 text-center"
              href="transport.html"
            >
              TRANSPORT
            </p>
          </div>
        </div>
        <div className="container" style={{ maxWidth: "800px" }}>
          {Screen === 1 ? (
            <div className="main row row-grid">
              <div className="col-lg-6 col-md-6 col-12 order-md-2 pl-10">
                <img
                  alt=""
                  className="image ml-10"
                  src="website icons/05.png"
                />
              </div>
              <div className="col-lg-6 col-md-6 col-12 order-md-1">
                <h2 className="font-weight-bold">BE YOUR OWN BOSS</h2>
                <p>
                  Grow your earnings by working with us on a<br />
                  Full-Time or Part-Time basis.
                </p>
                <p>Send Driver App download link to your phone</p>
                <div className="header-buttons">
                  <div className="mobile-no row m-0">
                    <div className="col-7 px-0 py-2">
                      <input type="text" placeholder="Phone " name="number" />
                    </div>
                    <div className="col-5 p-0">
                      <button type="button" className="extra-btn btn">
                        Text App Link
                      </button>
                    </div>

                    <br />
                  </div>
                  <Link
                    to="/driver"
                    type="Link"
                    className="btn-others btn my-3"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {Screen === 2 ? (
            <div className="main row row-grid">
              <div className="col-lg-6 col-md-6 order-md-2 pl-10">
                <img
                  alt=""
                  className="image ml-10"
                  src="website icons/06.png"
                />
              </div>
              <div className="col-lg-6 col-md-6 order-md-1">
                <h2 className="font-weight-bold">
                  COLLABORATE WITH BOOK TRANSPO
                </h2>
                <p>
                  Grow your earnings by working with us on a<br />
                  Full-Time or Part-Time basis.
                </p>
                <p>Send Partner App download link to your phone</p>
                <div className="header-buttons">
                  <div className="mobile-no row m-0">
                    <div className="col-7 px-0 py-2">
                      <input type="text" placeholder="Phone " name="number" />
                    </div>
                    <div className="col-5 p-0">
                      <button type="button" className="extra-btn btn">
                        Text App Link
                      </button>
                    </div>

                    <br />
                  </div>
                  <Link
                    to="/transport"
                    type="Link"
                    className="btn-others btn my-3"
                  >
                    Know More
                  </Link>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
      <section className="service container">
        <div className="heading1 text-center mx-auto">
          <h2 className="text-center font-weight-bold">WHAT WE OFFER</h2>
        </div>
        <div className="row">
          <div className=" col-lg-3 col-md-3 text-center">
            <img alt="" className="icons" src="website icons/07.png" />
            <h5>
              <strong>Track Your Load</strong>
            </h5>
            <p>Book Transpo allows to track the location in Real time</p>
          </div>

          <div className="col-lg-3 col-md-3  text-center">
            <img alt="" className="icons" src="website icons/08.png" />
            <h5>
              <strong>Professional Drivers</strong>
            </h5>
            <p>
              Now stay in touch with drivers and ensure cargo safet at every
              turn
            </p>
          </div>

          <div className="col-lg-3 col-md-3  text-center">
            <img alt="" className="icons" src="website icons/09.png" />
            <h5>
              <strong>Trusted Vendors</strong>
            </h5>
            <p>
              Access thousand of Trusted Transports who compete for your load
              Requests
            </p>
          </div>

          <div className="col-lg-3 col-md-3  text-center">
            <img alt="" className="icons" src="website icons/10.png" />
            <h5>
              <strong>Safe and Reliable Trucks</strong>
            </h5>
            <p>Ensure Safety of goods at every Mile we cross</p>
          </div>
        </div>
      </section>
      <section className="cities container">
        <div className="heading1  text-center mx-auto">
          <h2 className="text-center font-weight-bold">OUR GROWTH</h2>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-4 ">
            <div className="row justify-content-center">
              <div className="col" style={{ maxWidth: "145px" }}>
                <h1 className="count">
                  <strong>35,000+</strong>
                </h1>
              </div>
              <div className="col" style={{ maxWidth: "60px" }}>
                <span>Drivers Added</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="row justify-content-center">
              <div className="col" style={{ maxWidth: "100px" }}>
                <h1 className="count">
                  <strong>200+</strong>
                </h1>
              </div>
              <div className="col" style={{ maxWidth: "60px" }}>
                <span>Transporters Added</span>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-4">
            <div className="row justify-content-center">
              <div className="col" style={{ maxWidth: "120px" }}>
                <h1 className="count">
                  <strong>1000+</strong>
                </h1>
              </div>
              <div className="col" style={{ maxWidth: "60px" }}>
                <span>Happy Customers</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="cities container">
        <div className="heading1 text-center mx-auto">
          <h2 className="text-center font-weight-bold">OUR CITIES</h2>
        </div>
        <div className="row">
          <div className=" col-lg-3 col-md-3 col-6 text-center">
            <img alt="" className="icons" src="website icons/11.png" />
            <h6>Hydrabad</h6>
          </div>

          <div className="col-lg-3 col-md-3 col-6 text-center">
            <img alt="" className="icons" src="website icons/12.png" />
            <h6>Chennai</h6>
          </div>

          <div className="col-lg-3 col-md-3 col-6 text-center">
            <img alt="" className="icons" src="website icons/13.png" />
            <h6>Delhi</h6>
          </div>

          <div className="col-lg-3 col-md-3 col-6 text-center">
            <img alt="" className="icons" src="website icons/14.png" />
            <h6>Mumbai</h6>
          </div>
        </div>
      </section>
    </>
  );
}
