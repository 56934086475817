import React from "react";

export default function Page3() {
  return (
    <>
      <div class="plain"></div>
      <div class="container-fluid">
        <section>
          <div class="container text-center">
            <div class="heading2 text-center">
              <h4>OWN A TRANSPORT? ATTACH WITH BOOKRANSPO</h4>
              <p>Join The World's Fastest Growing Transport Chain</p>
            </div>
            <img
              alt=""
              class="truck "
              src="website icons/Website Transport Icon Revised-01.png"
            />
          </div>
        </section>
        <section class="container">
          <div class="heading2 text-center">
            <h4>I WOULD LIKE TO ATTACH</h4>
          </div>
          <div class="form">
            <div class="form-row row p-3">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input type="text" class="form-control" id="name" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="mobile">Mobile</label>
                  <input type="text" class="form-control" id="mobile" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="name">Email</label>
                  <input type="email" class="form-control" id="" />
                </div>
              </div>
            </div>
            <div class="form-row row p-3">
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="city"> City</label>
                  <input type="text" class="form-control" id="city" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="no_vec">Number of Vehicles</label>
                  <input type="text" class="form-control" id="no_vec" />
                </div>
              </div>
              <div class="col-lg-4 col-md-4 col-sm-12">
                <div class="form-group">
                  <label for="abt_us">Where did you here about us</label>
                  <input type="text" class="form-control" id="abt_us" />
                </div>
              </div>
            </div>
          </div>
          <div class="heading2 text-center">
            <h5>VISIT OFFICE TO REGISTER AS A DRIVER PARTNER</h5>
            <p>
              <h6>
                1B-63, 64 & 65 on First Floor of Phoenix Paragon Plaza,
                <br />
                Phoenix Market City, L.B.S. Marg, Kurla (W), Mumbai, 400070
              </h6>
            </p>
          </div>
        </section>
        <section class="container">
          <div class="heading2 text-center">
            <h4>BOOKTRANSPO BENIFTS</h4>
          </div>
          <div class="row text-center">
            <div class="col-lg-12 col-md-12">
              <img alt="" class="benefits-icons" src="website icons/32.png" />
              <br />
              <span class="benefits-heading">Continue Orders</span>
              <br />
            </div>
          </div>
          <div class="row text-center adj">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <img alt="" class="benefits-icons" src="website icons/36.png" />
              <br />
              <span class="benefits-heading">
                Get Customer insights to improve your business
              </span>
              <br />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <img alt="" class="benefits-icons" src="website icons/34.png" />
              <br />
              <span class="benefits-heading">
                Create Promotions to Grow Your Business
              </span>
              <br />
            </div>
          </div>
          <div class="row text-center">
            <div class="col-lg-12 col-md-12">
              <img alt="" class="benefits-icons" src="website icons/31.png" />
              <br />
              <span class="benefits-heading">Manage Your Live Inventory</span>
              <br />
            </div>
          </div>
          <div class="row text-center adj">
            <div class="col-lg-6 col-md-6 col-sm-6">
              <img alt="" class="benefits-icons" src="website icons/33.png" />
              <br />
              <span class="benefits-heading">
                Review Your Order History For Reconciliation and Analysis
              </span>
              <br />
            </div>
            <div class="col-lg-6 col-md-6 col-sm-6">
              <img alt="" class="benefits-icons" src="website icons/35.png" />
              <br />
              <span class="benefits-heading">
                View Your Daily, Weekly and Monthly Business
              </span>
              <br />
            </div>
          </div>
        </section>
        <section class="text-center">
          <div class="heading2 uppercase text-center">
            <h4>WHAT Transporters SAY ABOUT US</h4>
          </div>
          <div class="container">
            <div class="card-columns">
              <div class="card bg-color">
                <div class="card-body text-center">
                  <img alt="" class="quotes" src="website icons/30.png" />
                  <br />
                  <p class="card-text">
                    This collaboration with BookTranspo has been a key strategic
                    step for us, which has provided us significant customer
                    oppurtunities. Booktranspo serves as an important brand
                    investment for.
                  </p>
                  <p class="profile"></p>
                  Rohan Transport
                </div>
              </div>
              <div class="card bg-color">
                <div class="card-body text-center">
                  <img alt="" class="quotes" src="website icons/30.png" />
                  <br />
                  <p class="card-text">
                    BookTranspo extends our brand awareness to customers that
                    wouldn't normally be exposed to us. BookTranspo extends our
                    brand awareness to customers that wouldn't normally be
                    exposed to us.
                  </p>
                  <p class="profile"></p>
                  Mamta Transport
                </div>
              </div>
              <div class="card bg-color">
                <div class="card-body text-center">
                  <img alt="" class="quotes" src="website icons/30.png" />
                  <br />
                  <p class="card-text">
                    This partnership with BookTranspo has been very special for
                    us From 10 orders per day to 100 orders per day with them.
                    This wouldn,t have been possible without BookTranspo and
                    their super delivery services.
                  </p>
                  <p class="profile"></p>
                  Hindustan Transport
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="font text-center">
            <h5>FOR ANY MORE QUERY</h5>
            Reach us out on 080 4410 4499 today to know more
            <br />
            about us and get benefits from our managed services
            <br />
            offering. Our expert team will be happy to know more
            <br />
            about your business and recommend the best possible
            <br />
            solutions tailored to your logistics needs
          </div>
        </section>
      </div>
    </>
  );
}
