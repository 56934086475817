import React from "react";
import { Link } from "react-router-dom";

const NavBar = (props) => {
  return (
    <nav class="navbar navbar-expand-lg navbar-light fixed-top">
      <Link className="navbar-brand" to="/">
        <img alt="" className="nav-icon" src="website icons/39.png" />
      </Link>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="navbar-nav mr-auto"></div>
        <form class="form-inline my-2 my-lg-0 maya">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item active">
              <Link to="/driver" class="nav-link">
                Driver Partner <span className="sr-only">(current)</span>
              </Link>
            </li>
            <li className="nav-item">
              <Link class="nav-link" to="/transport">
                Transporters
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/aboutus" className="nav-link">
                About Us
              </Link>
            </li>
            <li className="nav-item">
              <Link class="nav-link" to="/contact">
                Contact Us
              </Link>
            </li>
          </ul>
        </form>
      </div>
    </nav>
  );
};

NavBar.propTypes = {};

export default NavBar;
